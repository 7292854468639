import axios from "axios";
import qs from "qs";
import store from "@/store/index";
import { randomNum } from "@/utils";
import { Message } from "element-ui";
import md5 from "md5";

// 不同环境不同地址
const apis = {
  // production: "http://test.iqty.com", // 测试环境
  // production: "https://api.kuqiu.cn", // 线上地址 (生产环境)
  production: "http://pia.woodui.com", // 线上地址 (国外生产环境)
  development: "/api", // 本地 (开发环境)
};
let randNum = randomNum(0, 9);
const config = {
  // 默认地址请求地址
  baseURL:
    process.env.NODE_ENV === "production" ? apis.production : apis.development,
  // 设置超时时间（一分钟）
  timeout: 60000,
  // 跨域时候允许携带凭证
  withCredentials: true,
  headers: {
    rand: randNum,
    sign: md5(randNum + "Fr&U!@ef"),
    // eslint-disable-next-line no-undef
    device: "PC",
    token: store.state.token,
    // "Content-Type": "text/plain",
  },
};
const http = axios.create(config);

// 请求拦截
http.interceptors.request.use(
  (config) => {
    // 转为表单格式
    config.data = qs.stringify(config.data);
    // vuex中取token
    const token = store.state.token;
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
http.interceptors.response.use(
  (response) => {
    const { data } = response;
    if (data.code !== 200) {
      switch (data.code) {
        // 接口数据错误
        case 1000:
          // Message.error(data.msg);
          break;
        // token失效
        case 1001:
          // Message.error(data.msg);
          store.commit("setLoseTokenMaskShow", true);
          // 清除vuex中的token
          store.commit("setToken", null);
          store.commit("setUserInfo", {});
          break;
        // 没有访问权限
        case 1002:
          // Message.error(data.msg);
          break;
        // 找不到资源
        case 403:
          window.location.href = "/#/home/index";
          // Message.error(data.msg);
          break;
        // 系统提示错误
        case 500:
          // Message.error(data.msg);
          break;
      }

      return Promise.reject(data);
    } else {
      return data;
    }
  },
  (error) => {
    const { response } = error;
    if (response && response.data) {
      return Promise.reject(error);
    }
    const { message } = error;
    Message.error(message);
    return Promise.reject(error);
  }
);

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const apiPost = (url, data = {}) => {
  return http.post(url, data);
};

/**
 * @param {string} url
 * @param {object} params
 */
export const apiGet = (url, params = {}) => {
  return http.get(url, { params });
};

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export const apiPut = (url, data = {}, params = {}) => {
  return http({
    method: "put",
    url,
    params,
    data,
  });
};

/**
 * @param {string} url
 * @param {object} params
 */
export const apiDelete = (url, params = {}) => {
  return http({
    method: "delete",
    url,
    params,
  });
};

export default http;
